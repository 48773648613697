<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import AwesomeVueStarRating from 'awesome-vue-star-rating';
// import { required } from "vuelidate/lib/validators";

import {
  loadControlCriteriasApi,
} from "@/api/common";
export default {
  page: {
    title: "Nouveau control",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, AwesomeVueStarRating },
  import: {
    loadControlCriteriasApi,
  },
  data() {
    return {
      score: "",
      rating: {
        star: 1,
        ratingdescription: [
        {
          text: 'Faible',
          class: 'star-poor'
        },
        {
          text: 'Faible',
          class: 'star-poor'
        },
        {
          text: 'Faible',
          class: 'star-poor'
        },
        {
          text: 'Sous la moyenne',
          class: 'star-belowAverage'
        },
        {
          text: 'Moyen',
          class: 'star-average'
        },
        {
          text: 'Moyen',
          class: 'star-average'
        },
        {
          text: 'Bien',
          class: 'star-good'
        },
        {
          text: 'Bien',
          class: 'star-good'
        },
        {
          text: 'Très bien',
          class: 'star-excellent'
        },
        {
          text: 'Très bien',
          class: 'star-excellent'
        }
      ],
      hasresults: true,
      hasdescription: true,
      starsize: '2x', //[xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
      maxstars: 10,
      minstars: 1,
      disabled: false
      },
      title: "Nouveau control",
      criterias: [],
      type : this.$route.params.category,
      controlForm: {
        date: new Date().toISOString().substr(0, 10),
        criterias: [],
        score: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "HSE",
          href: "/",
        },
        {
          text: "Contrôls",
          active: "/",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],
    };
  },

  validations: {
    controlForm: {
      // date: { required },
    },
  },

  created() {
   
    this.loadControlCriterias();
  },

  methods: {
    loadControlCriterias(){
      loadControlCriteriasApi(this.type)
      .then((res) => {
        var criterias = res.data.original.list;
        criterias.forEach(criteria => {
          this.controlForm.criterias.push({
            id: criteria.id,
            display: criteria.name,
            score: "",
            comment: "",
          });
      });
      });
    },
    onStarInput(){
      alert('ddd');
    },
    formSubmit() {
      console.log(this);
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post(
          "/ops/requests/visitors_support/store",
          this.controlForm
        )
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "requests.visitors_support.index" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form
              class="needs-validation"
              @submit.prevent="formSubmit"
              enctype="multipart/form-data"
            >
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date *</label>
                    <input
                      type="date"
                      v-model="controlForm.date"
                      class="form-control"
                      name=""
                      id=""
                    />
                  </div>
                </div>
              </div>

              <h3>Notes:</h3>
              <hr />
              <div>
                <div
                  v-for="criteria in controlForm.criterias"
                  :key="criteria.display"
                  class="row"
                >
                  <div class="form-group col-lg-3">
                    <label for="message">Critère</label>
                    <input
                      id="subject"
                      disabled
                      readonly
                      v-model="criteria.display"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  
                  <div class="form-group col-lg-5">
                    <label for="subject">Score</label>
                    <AwesomeVueStarRating 
                    :star="rating.star" 
                    :disabled="rating.disabled" 
                    :maxstars="rating.maxstars" 
                    :starsize="rating.starsize" 
                    :hasresults="rating.hasresults" 
                    :hasdescription="rating.hasdescription" 
                    :ratingdescription="rating.ratingdescription" 
                    @click="onStarInput"
                    :model="rating.star"
                    />
                   
                  </div>

                  <div class="form-group col-lg-4">
                    <label for="message">Commentaires</label>
                    <input
                      id="subject"
                      v-model="criteria.comment"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="controlForm.notes"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div>
                <button
                  :disabled="$v.controlForm.$invalid"
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
